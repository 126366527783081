<template>
  <section class="section section-feedback outro-bg">
    <div class="outro">
      <figure>
        <img src="@/assets/i/r-logo.svg" alt="" />
      </figure>
      <p>
        {{ $t("feedback.complete_text_1") }}<br />
        {{ $t("feedback.complete_text_2") }}

        <br /><button
          type="button"
          class="button"
          @click="$emit('finish', true)"
        >
          {{ $t("feedback.complete_button") }}
        </button>
      </p>
    </div>
  </section></template
>

<script>
export default {
  name: "Complete",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    other: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style scoped></style>
