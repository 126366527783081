<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-feedback intro-bg" v-if="!start">
      <div class="intro">
        <figure>
          <img src="@/assets/i/r-logo.svg" alt="" />
        </figure>
        <p>
          {{ $t("feedback.start_text") }}
          <br /><a href="#" @click.prevent="start = true" class="button next"
            ><span> {{ $t("feedback.button_start") }}</span></a
          >
        </p>
      </div>
    </section>
    <section class="section section-feedback inner" v-else-if="!complete">
      <div
        class="question"
        :class="{ 'question-with-stars': currentQuestion.type === 'range1-5' }"
      >
        <form @submit.prevent="nextQuestion" :data-type="currentQuestion.type">
          <span class="number">
            {{ $t("feedback.question_number") }} №{{ currentIndex }}/{{
              allQuestion.length
            }}</span
          >

          <h2>
            {{ currentQuestion.text }}
          </h2>
          <p class="description" v-if="currentQuestion.subtext">
            {{ currentQuestion.subtext }}
          </p>
          <component
            v-bind:is="currentComponent"
            ref="componentRef"
            :items="currentQuestion.options"
            :other="currentQuestion.hasOther"
            :questionId="currentQuestion.id"
          ></component>
          <p class="buttons" v-if="currentQuestion.type !== 'continue'">
            <button type="submit" class="button next">
              <span>{{ $t("feedback.button_next") }}</span>
            </button>
          </p>
        </form>
      </div>
    </section>
    <complete v-else-if="complete" @finish="finish"></complete>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Complete from "@/components/feedback/Complete";

export default {
  name: "Feedback",
  components: { Complete },
  title() {
    return this.$t("feedback.caption");
  },
  data: () => {
    return {
      start: false,
      questionNumberData: false,
      answer: [],
      replaceType: {
        text: "Form",
        radiobutton: "Radiobutton",
        checkbox: "Checkbox",
        "range1-5": "Stars",
        continue: "Continue",
        textarea: "Text",
        "range1-10": "Scale"
      }
    };
  },
  watch: {
    indexLoading: {
      handler() {
        if (this.indexLoading === "empty") {
          this.actionIndex()
            .then(() => {
              console.log(this.indexData);
              let hiddenQuestion = this.indexData["questions"].findIndex(
                it => it.type === "hidden"
              );

              let qN = 0;
              if (!this.answer.length) {
                const hiddenVal = this.indexData["questions"][hiddenQuestion];
                if (hiddenQuestion !== -1) {
                  qN++;

                  if (hiddenVal.options && hiddenVal.options.length) {
                    for (let i = 0; i < hiddenVal.options.length; i++) {
                      if (hiddenVal.options[i].text === "IsEconomClass") {
                        this.answer.push({
                          questionnaireId: +localStorage.getItem(
                            "questionareId"
                          ),
                          questionId: hiddenVal.id,
                          answer: 1, //тут нужно указать эконом класс или нет
                          optionId: hiddenVal.options[i].id
                        });
                      }
                    }
                  }
                  // this.questionNumber = this.indexData["questions"][hiddenQuestion + 1].id;
                }
                // else {
                //   // this.questionNumber = this.indexData["questions"][
                //   //   this.indexData["questions"].length - 1
                //   // ].id;

                //   // todo
                //   this.questionNumber = this.indexData["questions"][0].id
                // }

                this.questionNumber = this.indexData["questions"][qN].id;
              }
            })
            .catch(console.log);
        }
      },
      immediate: true
    }
  },
  computed: {
    questionNumber: {
      get() {
        return this.questionNumberData;
      },
      set(val) {
        if (this.answer.length) {
          let item = this.allQuestion.find(it => it.id === val);
          if (item.dependencies) {
            for (let i = 0; i < item.dependencies.length; i++) {
              let dependencyAnswerObject = this.answer.find(
                it => it.questionId === item.dependencies[i].questionId
              );
              if (item.dependencies[i].optionId === 0) {
                if (
                  item.dependencies[i].answerValue <=
                    parseInt(dependencyAnswerObject.answer) &&
                  item.dependencies[i].answerValueMax >=
                    parseInt(dependencyAnswerObject.answer)
                ) {
                  this.questionNumberData = val;
                  return;
                } else {
                  if (this.allQuestion[this.currentIndex + 2]) {
                    this.questionNumber = this.allQuestion[
                      this.allQuestion.findIndex(it => it.id === val) + 1
                    ].id;
                  }
                }
              } else {
                this.questionNumberData = val;
              }
            }
          } else {
            this.questionNumberData = val;
          }
        } else {
          this.questionNumberData = val;
        }
      }
    },
    ...mapState("feedback", {
      indexData: state => state.index,
      indexLoading: state => state.indexLoading,
      complete: state => state.complete
    }),
    currentQuestion() {
      return this.allQuestion.find(it => it.id === this.questionNumber);
    },
    currentIndex() {
      return this.indexData["questions"].findIndex(
        it => it.id === this.questionNumber
      );
    },
    allQuestion() {
      return this.indexData["questions"].filter(it => it.type !== "hidden");
    },

    currentComponent() {
      if (this.currentQuestion.type) {
        return () =>
          import(
            "@/components/feedback/" +
              this.replaceType[this.currentQuestion.type] +
              ".vue"
          );
      }
      return false;
    }
  },
  created() {
    if (this.complete === false) {
      this.answer = [];
    }
    this.updateEntity(["complete", false]);
    this.updateEntity(["indexLoading", "empty"]);
  },
  methods: {
    ...mapMutations("feedback", {
      updateEntity: "SET_ENTITY"
    }),
    ...mapActions("feedback", {
      actionIndex: "index",
      actionPushFeedback: "pushFeedback"
    }),
    finish() {
      console.log("finish");
      console.log(this.answer);
      this.actionPushFeedback(this.answer);

      this.$router.push("/");
    },
    getCurrentDependendedQuestions(id) {
      return this.indexData["questions"].filter(it => {
        return it.dependencies && it.dependencies[0].questionId === id;
      });
    },
    getNeededQuestion(aQuestions, resultValue) {
      return aQuestions.filter(q => {
        return (
          resultValue >= q.dependencies[0].answerValue &&
          resultValue <= q.dependencies[0].answerValueMax
        );
      });
    },
    nextQuestion() {
      let result = this.$refs.componentRef.getAnswer();
      if (Array.isArray(result)) {
        result.map(item => this.answer.push(item));
      } else {
        this.answer.push(result);
        if (result.finish) {
          this.updateEntity(["complete", true]);
          return;
        }
      }
      if (this.indexData["questions"][this.currentIndex + 1]) {
        if (
          this.indexData["questions"][this.currentIndex].dependencies &&
          this.indexData["questions"][this.currentIndex + 1].dependencies
        ) {
          let aCurrentDependendedQuestions = this.getCurrentDependendedQuestions(
            this.indexData["questions"][this.currentIndex].dependencies[0]
              .questionId
          );
          let lastDependensesNumber =
            aCurrentDependendedQuestions[
              aCurrentDependendedQuestions.length - 1
            ].id;
          this.questionNumber = lastDependensesNumber + 1;
          return;
        }
        if (this.indexData["questions"][this.currentIndex + 1].dependencies) {
          let aCurrentDependendedQuestions = this.getCurrentDependendedQuestions(
            this.indexData["questions"][this.currentIndex].id
          );
          const neededQuestion = this.getNeededQuestion(
            aCurrentDependendedQuestions,
            result.answer
          )[0];
          if (neededQuestion) {
            this.questionNumber = neededQuestion.id;
          } else {
            this.questionNumber = this.indexData["questions"][
              this.currentIndex + 2
            ].id;
          }
        } else {
          this.questionNumber = this.indexData["questions"][
            this.currentIndex + 1
          ].id;
        }
      } else {
        this.updateEntity(["complete", true]);
      }
    }
  }
};
</script>

<style scoped>

</style>
